import React from "react";
import Layout from "../layouts";
import { graphql } from "gatsby";
import SEO from "../components/seo";

// Sections
import {
  Content,
} from "../components/blog";

const sections = {
  Content: Content,
};

const TemplateBlogPost = (pageContext) => {
  console.log('Blog Post Section');
  console.log(pageContext);

  const page_data = pageContext.data;
  const page_references = page_data.contentfulTemplateBlogPost;
  const Section = sections["Content"];
  return (
    <Layout>
      <SEO
        description={page_references.description.description}
        title={page_references.title}
      />
      <Section data={page_references}/>
    </Layout>
  );
};

export default TemplateBlogPost;
export const query = graphql`
query TemplateBlogPostQuery($path: String) {
  contentfulTemplateBlogPost(path: {eq: $path}) {
    title
    path
    image {
      title
      gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      file{
        url
      }
    }
    videoImagePlaceHolder {
      title
      gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
    }
    author
    category
    date(formatString: "MMMM DD, YYYY")
    description {
      description
    }
    body {
      raw
      references {
        ... on ContentfulAsset {
          contentful_id
          gatsbyImageData(layout: CONSTRAINED, quality: 80, formats: [WEBP, AUTO], placeholder: BLURRED)
          title
        }
        ... on ContentfulBlogComponentButton {
          contentful_id
          buttonText
          buttonLink
        }
        ... on ContentfulBlogComponentImageSWithWithoutText {
          contentful_id
          imagePlacement
          images {
            gatsbyImageData(layout: CONSTRAINED, quality: 80, formats: [WEBP, AUTO], placeholder: BLURRED)
            title
          }
          richtext {
            raw
          }
          gifPlacement
          gifs {
            title
            url
          }
        }
      }
    }
    readTime
    calendly
    form
  }
}
`;

